<template>
  <div>
    <v-container >
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            1. Первичные средства пожаротушения
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
            >
            <strong>Первичные средства пожаротушения </strong> - это устройства, инструменты и материалы, предназначенные для использования работниками организаций, личным составом подразделений пожарной охраны в целях тушения пожаров.
          </v-alert>
        </v-col>
        <v-col
        cols="6"
        >
        <v-card
        class="pa-2 d-flex justify-center align-center"
        full-width
        height="180"

        >
          <v-img
            lazy-src="img/material/fire.svg"
            max-width="80"
            src="img/material/fire.svg"
            class="ma-1"
          ></v-img>
          <v-spacer></v-spacer>
          <v-card-text class="title font-weight-light">
            Первичные средства применяются для борьбы с загоранием на начальной стадии, а не с уже развившемся пожаром.
          </v-card-text>
        </v-card>
        </v-col>
        <v-col
        cols="6"
        >
        <v-card
        class="pa-2 d-flex justify-center align-center"
        height="180"
        >
          <v-img
            lazy-src="img/material/shovel.svg"
            max-width="80"
            src="img/material/shovel.svg"
            class="ma-1"
          ></v-img>
          <v-spacer></v-spacer>
          <v-card-text class="title font-weight-light">
            Запрещено: использование первичных средств, пожарного инструмента и инвентаря для нужд, не связанных с тушением пожара.
          </v-card-text>
        </v-card>
        </v-col>
        <v-col cols='12'>
          <v-card-title >
            Первичные средства пожаротушения подразделяются на:
          </v-card-title>
        </v-col>
        <v-col cols="4">
         <v-dialog
            v-model="dialog1"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/fire-extinguisher.svg"
                  max-width="80"
                  src="img/material/fire-extinguisher.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Переносные и передвижные огнетушители
                </v-card-text>
              </v-card>
            </template>
            <v-card>
              <Modal1></Modal1>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
         <v-dialog
            v-model="dialog2"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/bucket.svg"
                  max-width="80"
                  src="img/material/bucket.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Пожарный инвентарь
                </v-card-text>
              </v-card>
            </template>
            <v-card>
              <Modal2></Modal2>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
         <v-dialog
            v-model="dialog3"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/fire-hose.svg"
                  max-width="80"
                  src="img/material/fire-hose.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Пожарные краны и средства обеспечения их использования
                </v-card-text>
              </v-card>
            </template>
            <v-card>
              <Modal3></Modal3>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
         <v-dialog
            v-model="dialog4"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/bed-sheets.svg"
                  max-width="80"
                  src="img/material/bed-sheets.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Покрывало пожарное (кошма)
                </v-card-text>
              </v-card>
            </template>
            <v-card>
              <Modal4></Modal4>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog4 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
         <v-dialog
            v-model="dialog5"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/fire-shield.svg"
                  max-width="80"
                  src="img/material/fire-shield.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Пожарный щит
                </v-card-text>
              </v-card>
            </template>
            <v-card>
              <Modal5></Modal5>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog5 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
         <v-dialog
            v-model="dialog6"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/fire-cupboard.svg"
                  max-width="80"
                  src="img/material/fire-cupboard.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Пожарный шкаф
                </v-card-text>
              </v-card>
            </template>
            <v-card>
              <Modal6></Modal6>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog6 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
import Modal1 from '@/components/Page28/Modal1.vue'
import Modal2 from '@/components/Page28/Modal2.vue'
import Modal3 from '@/components/Page28/Modal3.vue'
import Modal4 from '@/components/Page28/Modal4.vue'
import Modal5 from '@/components/Page28/Modal5.vue'
import Modal6 from '@/components/Page28/Modal6.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null,
    dialog3: null,
    dialog4: null,
    dialog5: null,
    dialog6: null
  }),
  components: {
    Hint,
    Modal1,
    Modal2,
    Modal3,
    Modal4,
    Modal5,
    Modal6
  }
}
</script>
<style lang="less">
.ptm-act-tab-class {
  background-color: #FFCDD2;
  }
</style>
