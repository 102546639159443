<template>
  <div class="noselect">
    <v-card-title>
      Переносные и передвижные огнетушители
    </v-card-title>
    <v-card-text class="title font-weight-light">
      <span class="red--text title">Огнетушители </span>- устройства для тушения очага пожара за счет выпуска запасенного огнетушащего вещества.
    </v-card-text>
    <v-tabs active-class="ptm-act-tab-class" background-color="grey lighten-4" v-model="modal1" grow color="red">
      <v-tab>Общие требования</v-tab>
      <v-tab>Правила обращения</v-tab>
      <v-tab>Правила применения</v-tab>
    </v-tabs>
    <v-tabs-items v-model="modal1">
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="8">
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/evacuation-plan.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/evacuation-plan.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  должны обеспечивать тушение пожара одним человеком на площади, указанной в технической документации организации-изготовителя
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/worker.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/worker.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  технические характеристики огнетушителей должны обеспечивать безопасность человека при тушении пожара
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/fire-extinguisher.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/fire-extinguisher.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  прочностные характеристики конструктивных элементов должны обеспечивать безопасность их применения при тушении пожара
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols=4>
              <v-card  class="d-flex justify-center pa-5 mt-10">
                <v-img
                  lazy-src="img/material/fire-save-1.svg"
                  max-height="100%"
                  max-width="40%"
                  contain
                  src="img/material/fire-save-1.svg"
                >
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/fire-extinguisher-2.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/fire-extinguisher-2.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  используйте наветренную сторону и направляйте раструб прямо на очаг пожара
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/water-hose.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/water-hose.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  не тушите на длительном расстоянии. Оптимальное расстояние соответствует минимальной длине струи (указано на этикетке)
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/dippel-oil-2.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/dippel-oil-2.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  при тушении ЛВЖ (или горючих материалов) направляйте ствол на горящую поверхность, а не огонь. Вытекающую горящую жидкость начинайте ликвидировать сверху
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/brickwall.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/brickwall.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  если огнем охвачена стена, то тушить снизу и продвигаться наверх
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/extinguisher.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/extinguisher.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  для ускорения задействуйте несколько огнетушителей
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/fire-2.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/fire-2.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  не поворачивайтесь к пожару спиной, т.к. есть вероятность повторного возгорания
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3 "

              >
                <v-img
                  lazy-src="img/material/fire-extinguisher-3.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/fire-extinguisher-3.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  безопасное расстояния при ликвидации возгорания на электроустановках – 1м. Тушите с перерывами в 3 – 5 с.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                class="d-flex justify-center pa-3"

              >
                <v-img
                  lazy-src="img/material/hand.svg"
                  max-height="100%"
                  max-width="7%"
                  contain
                  src="img/material/hand.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  не торопитесь, тушите постепенно, без резких рывков
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justfy-center align-center">
                <v-card
                  flat
                  class="d-flex flex-column justify-center align-center pa-1"

                >
                  <v-img
                    lazy-src="img/material/step-1.svg"
                    width="300"
                    contain
                    src="img/material/step-1.svg"
                  >
                  </v-img>
                  <v-card-text class="title text-center font-weight-bold ml-5">
                    Сорвать пломбу или выдернуть чеку
                  </v-card-text>
                </v-card>
                <v-card
                  flat
                  class="d-flex flex-column justify-center align-center pa-1"

                >
                  <v-img
                    lazy-src="img/material/step-2.svg"
                    width="300"
                    contain
                    src="img/material/step-2.svg"
                  >
                  </v-img>
                  <v-card-text class="title text-center font-weight-bold ml-5">
                    Направить раструб с сторону пламени
                  </v-card-text>
                </v-card>
                <v-card
                  flat
                  class="d-flex flex-column justify-center align-center pa-1"

                >
                  <v-img
                    lazy-src="img/material/step-3.svg"
                    width="300"
                    contain
                    src="img/material/step-3.svg"
                  >
                  </v-img>
                  <v-card-text class="title text-center font-weight-bold ml-1">
                    Нажать на рычаг и водить по сторонам
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>

        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <Hint></Hint>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    modal1: false
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.noselect {
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -khtml-user-select: none;
}
</style>
