<template>
  <div class="noselect">
    <v-card-title>
      Пожарный кран
    </v-card-title>
    <v-card-text class="title font-weight-light mb-0 pb-0">
      <span class="red--text title ">Пожарный кран (ПК) :</span> это комплекс оборудования (узел) на внутреннем противопожарном водоводе для подачи воды в оснащение при тушении возгораний.
    </v-card-text>
    <v-tabs active-class="ptm-act-tab-class" background-color="grey lighten-4" v-model="modal1" grow color="red">
      <v-tab>Состав и принцип работы</v-tab>
      <v-tab>Правила применения</v-tab>
      <v-tab>Требования к содержанию</v-tab>
    </v-tabs>
    <v-tabs-items v-model="modal1">
      <v-tab-item>
        <v-container fluid>
          <v-row>
            <v-col cols="6">
                <div class="d-flex justify-center">
                  <v-img
                    lazy-src="img/material/fire-box.png"
                    max-width="600"
                    src="img/material/fire-box.png"
                    class="ma-1"
                  ></v-img>
                </div>

            </v-col>
            <v-col cols="6">
              <v-card flat>
                <v-card-title>
                  Состав:
                </v-card-title>
                <v-card-text class="title font-weight-light mb-0 pb-0">
                  <span class="red--text title">1. Пожарный вентиль</span> диаметром 50 или 65мм, присоединенный к ответвлению стояка;
                </v-card-text>
                <v-card-text class="title font-weight-light mb-0 pb-0">
                  <span class="red--text title">2. Пожарный ствол </span> с наконечником (спрыском) диаметром 13, 16, 19мм;
                </v-card-text>
                <v-card-text class="title font-weight-light mb-0 pb-0">
                  <span class="red--text title">3. Рукавная кассета; </span>
                </v-card-text>
                <v-card-text class="title font-weight-light ">
                  <span class="red--text title">4. Напорный рукав </span> длиной 10, 15, 20м с быстросмыкающимися полугайками.
                </v-card-text>
              </v-card>
              <v-card-title>
                Принцип работы:
              </v-card-title>
              <v-card-text class="title font-weight-light">
                золотник двигается шпинделем, передающем усилие при помощи ходового органа на запор, открывая/закрывая отверстие.
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="8">
              <v-tabs
                v-model="tab1"
                color="red"
                slider-color="white"
                class="mt-10 red"
                active-class="ptm-act-class"
              >
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-1-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-2-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-3-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-4-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-5-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-6-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-7-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
              </v-tabs>
              <v-tabs-items  v-model="tab1">

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Сорвать пломбу и открыть пожарный шкаф.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Снять пожарный шланг. <br>Он уже подсоединен и в режиме готовности со стволом.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Раскатать пожарный рукав: держа за внешний виток, сделать бросок в направлении очага так, чтобы не было скручиваний.
                    <br><span class="red--text font-weight-bold">НЕ ДОПУСКАТЬ: </span>резких изломов и скруток пожарного рукава.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Открыть клапан крана, повернув маховик к максимальной отметке.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10 mb-0 pb-0">
                    При наличии повысительного нагнетателя после открытия запора нажать на его кнопку.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Направить пожарный ствол и подать воду в очаг пожара.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    В процессе пожаротушения следят, чтобы путь к эвакуации и отходу был свободным.
                  </v-card-text>
                </v-tab-item>

              </v-tabs-items>
            </v-col>
            <v-col cols="4">
                <v-img
                  lazy-src="img/material/fire-man-1.svg"
                  max-width="100%"
                  src="img/material/fire-man-1.svg"
                  class="pa-2"
                ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
            <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-tabs
                v-model="tab2"
                color="red"
                slider-color="white"
                class="mt-10 red text-left"
                active-class="ptm-act-class"
                vertical
              >
                <v-tab class="px-0 justify-start">
                  <v-card-title>
                    Конструкции
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0 justify-start">
                  <v-card-title>
                    Проверки
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0 justify-start">
                  <v-card-title>
                    Размещения
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0 justify-start">
                  <v-card-title>
                    Запрещено
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0 justify-start">
                  <v-card-title>
                    Знаки ПК
                  </v-card-title>
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="8">
              <v-tabs-items vertical  v-model="tab2">
                <v-tab-item>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Конструкция ПК обеспечивает возможность открывания запорного устройства 1 человеком и подачи поды с интенсивностью, обеспечивающей тушение пожара;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Соединительные головки ПК позволяют подсоединять к ним пожарные рукава, используемые в подразделениях пожарной охраны;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Длина рукава не менее предусмотренной проектом (10, 15, 20 метров);
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Рукав и ствол соединены между собой и подсоединены к ПК;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Время работы ПК – 3ч.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Проверка на исправность с пуском воды и сушка рукава перед установкой на ПК;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Перекатка рукавов на новую скатку не реже 1 раза в год (льняные – не реже 1 раза в 6 месяцев);
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Проверка работоспособности сетей пожарного водопровода на водоотдачу не реже 2 раз в год (весной и осенью) с пуском воды;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Регулярная замена набивок сальника, прокладок, уплотнителей;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Нумерация всех ПК на объекте.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    К внутренним ПК обеспечивается свободный доступ;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Места размещения внутренних ПК обеспечиваются знаками «Не загромождать»;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    ПК устанавливаются на высоте 1,35 м над полом помещения;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    ПК размещаются в шкаф с надписью «ПК»:
                    <v-card-text class="title font-weight-light mb-0 pb-0 pl-10">
                      - в отапливаемых помещениях (от нагревательных приборов минимум 1 м);
                    </v-card-text>
                    <v-card-text class="title font-weight-light mb-0 pb-0 pl-10">
                      - в легкодоступных местах (на лестничных площадках, в вестибюлях, коридорах, проходах).
                    </v-card-text>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                    монтировать ПК маховиком вниз;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                    складировать около ПК материалы, оборудование;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                    захламлять проходы к узлу;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                    Проводить перепланировку, ремонт, затрудняющий доступ к ПК. На период ремонта, отключения поды обеспечиваются дополнительные меры ПБ, усиливается п/п режим;
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                    Оставлять кран в промежуточном положении, должен быть полностью  открыт или закрыт.
                  </v-card-text>
                   <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Выявленные неисправности в техническом состоянии ПК, рукавов и сетей противопожарного водопровода должны быть устранены в кратчайшие сроки.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                    <v-card-title>
                      Варианты знаков по ГОСТ 12.4.026-2015 для ПК:
                    </v-card-title>
                    <v-card
                      class="pa-2 mb-5 d-flex justify-center align-center"
                      height="110"
                      flat
                    >
                    <v-img
                      lazy-src="img/material/fire-img-1.png"
                      max-width="80"
                      src="img/material/fire-img-1.png"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      В местах нахождения комплекта ПК с пожарным рукавом и стволом
                    </v-card-text>
                  </v-card>
                    <v-card
                      class="pa-2 mb-5 d-flex justify-center align-center"
                      height="110"
                      flat
                    >
                    <v-img
                      lazy-src="img/material/fire-img-2.png"
                      max-width="80"
                      src="img/material/fire-img-2.png"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      Указывает место установки ПК в здании, либо путь к месту установки – в сочетании с направляющими стрелками
                    </v-card-text>
                  </v-card>
                    <v-card
                      class="pa-2 mb-5 d-flex justify-center align-center"
                      height="110"
                      flat
                    >
                    <v-img
                      lazy-src="img/material/fire-img-3.png"
                      max-width="80"
                      src="img/material/fire-img-3.png"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      Применяют на чертежах (ГОСТ 21.205-2016)
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    В процессе пожаротушения следят, чтобы путь к эвакуации и отходу был свободным.
                  </v-card-text>
                </v-tab-item>

              </v-tabs-items>
            </v-col>
          </v-row>

        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <Hint></Hint>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    modal1: null,
    tab1: null,
    tab2: null
  }),
  components: {
    Hint
  }
}
</script>
