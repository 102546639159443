<template>
  <div class="noselect">
    <v-card-title>
      Переносные и передвижные огнетушители
    </v-card-title>
    <v-card-text class="title font-weight-light pb-0">
      <span class="red--text title"> Покрывало пожарное (кошма) </span>- прямоугольный кусок полотна из асбестовой, стекловолоконной ткани, войлока или брезента. Могут предусматриваться ручки для вытягивания из чехла.
    </v-card-text>
    <v-card-text class="title font-weight-light mb-3">
      <span class="red--text title"> Назначение:</span> изоляция от доступа кислорода при небольшом очаге горения.
    </v-card-text>
    <v-tabs active-class="ptm-act-tab-class" background-color="grey lighten-4" v-model="modal1" grow color="red">
      <v-tab>Общие требования</v-tab>
      <v-tab>Правила обращения</v-tab>
      <v-tab>Правила применения</v-tab>
    </v-tabs>
    <v-tabs-items v-model="modal1">
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-card flat  class="d-flex justify-center pa-5">
                <v-img
                  lazy-src="img/material/koshma.png"
                  max-height="100%"
                  max-width="100%"
                  contain
                  src="img/material/koshma.png"
                >
                </v-img>
              </v-card>
            </v-col>
            <v-col cols=8>
              <v-card-title class="mb-0 pb-0">
                Размер не менее 1 х 1 метров
              </v-card-title>
              <v-card-text class="title font-weight-light">
                В помещениях, где применяются или хранятся ЛВЖ или ГЖ, размеры полотен не менее 2 х 1,5 метра.
              </v-card-text>
              <v-card-title class="mb-0 pb-0">
                Хранение
              </v-card-title>
              <v-card-text class="title font-weight-light">
                В водонепроницаемых закрывающихся футлярах, позволяющих быстро применить средства при пожаре.
              </v-card-text>
              <v-card-title class="mb-0 pb-0">
                Проверка 1 раз в год
              </v-card-title>
              <v-card-text class="title font-weight-light">
                1 раз в год на отсутствие механических повреждений и целостности с внесением информации в журнал эксплуатации систем противопожарной защиты.
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/burning.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/burning.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  тушение горящей одежды на людях
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/sofa.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/sofa.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  защита сгораемых строительных конструкций, оборудования, отделки, мебели
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/welder.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/welder.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  при проведении огневых, газо- и электросварочных работ в качестве пологов, экранов
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/car.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/car.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  дополнительное первичное средство пожаротушения в автомобиле
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/high-voltage.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/high-voltage.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  ликвидация небольших по площади очагов возгорания материалов, электроустановок до 1 000 В
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-tabs
                v-model="tab1"
                color="red"
                slider-color="white"
                class="mt-10 red"
                active-class="ptm-act-class"
              >
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-1-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-2-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-3-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-4-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
              </v-tabs>
              <v-tabs-items  v-model="tab1">

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Достать полотно из чехла и расправить.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Держать за ручки и располагать перед горящим объектом.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Накинуть на человека или предмет. Укутать тело, но не голову, не прижимая плотно, чтобы не добавить ожогов. <br> Противопожарной кошмой можно сбить пламя, если накрыть его не удается.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Осмотреть полотно после применения.
                  </v-card-text>
                </v-tab-item>

              </v-tabs-items>
            </v-col>
            <v-col cols="6">
              <v-card class="pa-3 mb-5 mt-10">
                <v-card-title class="red--text">
                  ЗАПРЕЩЕНО повторно использовать полотно, если:
                </v-card-title>
                <v-card-text class="title font-weight-light mb-0 pb-0">
                  <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                  есть разрывы, прожженные участки, оторваны ручки;
                </v-card-text>
                <v-card-text class="title font-weight-light mb-0 pb-0">
                  <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                  им тушили жидкие маслянистые вещества.
                </v-card-text>
              </v-card>
              <v-card class="pa-3 mb-5">
                <v-card-title class="red--text">
                  Если пожар не открытой местности:
                </v-card-title>
                <v-card-text class="title font-weight-light mb-0 pb-0">
                  то проходят с наветренной стороны, и накрывают огонь движением от себя.
                </v-card-text>
              </v-card>
              <v-card>

              </v-card>
            </v-col>
          </v-row>

        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <Hint></Hint>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    modal1: false,
    tab1: null
  }),
  components: {
    Hint
  }
}
</script>
