<template>
    <div class="noselect">
    <v-card-title>
      Пожарный щит
    </v-card-title>
    <v-card-text class="title font-weight-light pb-10">
      <span class="red--text title"> Назначение: </span>обеспечение доступа персонала предприятия к средствам пожаротушения. Для легкости определения местоположения окрашиваются в ярко-красный цвет (допускается контрастная окраска – белая с красной окантовкой).
    </v-card-text>
    <v-tabs active-class="ptm-act-tab-class" background-color="grey lighten-4" v-model="modal5" grow color="red">
      <v-tab>Требования к содержанию</v-tab>
      <v-tab>Виды</v-tab>
      <v-tab>Размещение</v-tab>
    </v-tabs>
    <v-tabs-items v-model="modal5">
      <v-tab-item>
        <v-container>
          <v-row>

            <v-col cols="6">
              <v-card
                class="pa-2 d-flex justify-center align-center"
                flat

              >
                <v-img
                  lazy-src="img/material/fire-box-open.png"
                  max-width="100%"
                  src="img/material/fire-box-open.png"
                  class="ma-1"
                ></v-img>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-tabs
                v-model="tab1"
                color="red"
                slider-color="white"
                class="mt-10 red"
                active-class="ptm-act-class"
              >
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-1-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-2-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-3-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-4-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-5-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
                <v-tab class="px-0">
                  <v-card-title>
                    <v-icon size="35">mdi-numeric-6-box-multiple-outline</v-icon>
                  </v-card-title>
                </v-tab>
              </v-tabs>
              <v-tabs-items  v-model="tab1">

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Комплектация должна отвечать категории и виду объекта защиты, требованиям правил ПБ.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Пожарный инвентарь устанавливается на видных местах так, чтобы он не служил препятствием на путях эвакуации.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    К щиту обеспечивается свободный доступ и наилучшая видимость с различных точек территории объекта.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Ручной инструмент подвергается периодическому обслуживанию:
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    осмотр, очистка от пыли, коррозионных повреждений металлических частей;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    восстановление окраски;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    правка ломов, багров в случае необходимости;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    заточка режущих, рубящих, граней.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    На щите указывается порядковый номер и телефонный номер ближайшего пожарного подразделения.
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <v-card-text class="title font-weight-light mt-10">
                    Дверцы закрыты и опломбированы, если имеют соответствующие для этого приспособления.
                  </v-card-text>
                </v-tab-item>

              </v-tabs-items>
            </v-col>
          </v-row>

        </v-container>
      </v-tab-item>

      <v-tab-item>
        <v-container fluid class="px-5">
          <v-row>
            <v-col cols="4">
              <v-card flat class="d-flex flex-column justify-center align-center pa-5 mt-5">
                <v-img
                  lazy-src="img/material/fire-box-open-type.png"
                  max-height="100%"
                  max-width="100%"
                  contain
                  src="img/material/fire-box-open-type.png"
                >
                </v-img>
                <v-card-title class="font-weight-light">
                  Щит открытого типа
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols=4>
              <v-card-title class="mb-0 pb-0">
                Виды щитов в зависимости от класса<br> пожара:
              </v-card-title>
              <v-card-text class="title font-weight-light mb-0 pb-0 mt-2">
                <span class="red--text font-weight-bold"> ЩП-А </span>
                 – для тушения твердых горючих материалов (дерево, текстиль и т.п.);
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <span class="red--text font-weight-bold"> ЩП-В </span>
                – для тушения горючих жидкостей и пластмасс;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <span class="red--text font-weight-bold"> ЩП-Е </span>
               – для тушения электроустановок;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <span class="red--text font-weight-bold">ЩП-СХ</span> – для тушения на сельскохозяйственных предприятиях;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <span class="red--text font-weight-bold">ЩПП</span>
                – для тушения в местах производства временных огневых работ (сварочные, наплавление и т.п.).
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card flat class="d-flex flex-column justify-center align-center pa-5 mt-5">
                <v-img
                  lazy-src="img/material/fire-box-close-type.png"
                  max-height="100%"
                  max-width="100%"
                  contain
                  src="img/material/fire-box-close-type.png"
                >
                </v-img>
                <v-card-title class="font-weight-light">
                  Щит закрытого типа
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="6">

              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/fire-hose.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/fire-hose.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  в производственных и складских
                  <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on }">
                    <span
                      class="blue lighten-4"
                      v-on="on"
                    >помещениях</span>
                  </template>
                  <span class="pa-5 pl-0 subtitle-1">За исключением зданий, оборудовать которые установками пожаротушения<br> и внутренним противопожарным водопроводом не требуется</span>
                </v-tooltip>
                   не оборудованных внутренним противопожарным водопроводом и
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on }">
                    <span
                      class="blue lighten-4"
                      v-on="on"
                    >АУП</span>
                  </template>
                  <span class="pa-5 pl-0 subtitle-1">Автоматическая установка пожаротушения</span>
                </v-tooltip>

                </v-card-text>
              </v-card>

              <v-card
                flat
                class="d-flex pa-3 pb-0"
              >
                <v-img
                  lazy-src="img/material/fire-hydrant.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/fire-hydrant.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  на территории предприятий, не имеющих наружного противопожарного водопровода
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"
              >
                <v-img
                  lazy-src="img/material/flour.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/flour.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  помещения и площадки предприятий по первичной переработке сельскохозяйственных культур
                </v-card-text>
              </v-card>

            </v-col>

            <v-col cols="6">

              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/welder.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/welder.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  помещения, в которых проводятся огневые работы
                </v-card-text>
              </v-card>

              <v-card
                flat
                class="d-flex pa-3 pb-0"

              >
                <v-img
                  lazy-src="img/material/route.svg"
                  max-height="100%"
                  max-width="15%"
                  contain
                  src="img/material/route.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light ml-5">
                  при удалении зданий, наружных технологических установок этих предприятий на расстояние более 100 метров от источников наружного противопожарного водоснабжения
                </v-card-text>
              </v-card>

            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <Hint></Hint>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    modal5: null,
    tab1: null
  }),
  components: {
    Hint
  }
}
</script>
