<template>
  <div class="noselect">
    <v-card-title>
      Пожарный инвентарь
    </v-card-title>
    <v-card-text class="title font-weight-light mb-0 pb-0">
      <span class="red--text title ">ЗАПРЕЩЕНО:</span> Применять пожарный инвентарь, инструмент в хозяйственных целях.
    </v-card-text>
    <v-card-title>
      Основой пожарный инвентарь и его назначение:
    </v-card-title>
    <v-container fluid class="px-10">
      <v-row>
        <v-col cols="3">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/fire-inventory-1.svg"
              max-width="80"
              src="img/material/fire-inventory-1.svg"
              class="ma-5"
              height="150"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal1 = true"
              >
                Конусообразное ведро
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal1"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Конусообразное ведро
                  </p>
                  <p class="subtitle-1 font-weight-light">Зачерпывание воды. <br>Благодаря форме не задевается ногой при переноске бегом к месту пожара</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal1 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/fire-inventory-4.svg"
              max-width="300"
              height="150"
              src="img/material/fire-inventory-4.svg"
              class="ma-5"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal2 = true"
              >
                Совковая лопата
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal2"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Совковая лопата
                  </p>
                  <p class="subtitle-1 font-weight-light">Отсыпка негорючими сыпучими материалами периметра вокруг горящего розлива ГЖ, а затем ликвидация горения в его границах</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal2 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/fire-inventory-3.svg"
              max-width="300"
              height="150"
              src="img/material/fire-inventory-3.svg"
              class="ma-5"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal3 = true"
              >
                Штыковая лопата
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal3"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Штыковая лопата
                  </p>
                  <p class="subtitle-1 font-weight-light">Подача негорючих материалов (песка, земли, снега, щебня, гравия) в огонь; набор земли, срезая плотный верхний слой почвы</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal3 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/fire-inventory-6.svg"
              max-width="300"
              height="150"
              src="img/material/fire-inventory-6.svg"
              class="ma-5"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal4 = true"
              >
                Бочка для воды
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal4"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Бочка для воды
                  </p>
                  <p class="subtitle-1 font-weight-light">Устанавливаются рядом с щитом. Объем не менее 0,2 м³, комплектуются ведрами</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal4 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/fire-inventory-5.svg"
              max-width="300"
              height="150"
              src="img/material/fire-inventory-5.svg"
              class="ma-5"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal5 = true"
              >
                Ломы и топоры
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal5"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary mb-0">
                    Ломы и топоры
                  </p>
                  <p class="subtitle-1 font-weight-light mb-1">Cиловые вскрытие запертых на замки ворот, двери окон. Разрушение внутренних или межкомнатных перегородок. Вскрытие полов, перекрытий, вентиляционных шахт, каналов, коробов, обрешетки и др.</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal5 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/fire-inventory-2.svg"
              width="400"
              height="150"
              src="img/material/fire-inventory-2.svg"
              class="ma-5"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal6 = true"
              >
                Багры
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal6"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Багры
                  </p>
                  <p class="subtitle-1 font-weight-light">Растаскивание, разборка горящих элементов деревянных зданий, надворных построек – досок стен сараев/стает, заборов, стропил, обрешетки, сгораемой кровли</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal6 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/fire-inventory-7.svg"
              width="400"
              height="150"
              src="img/material/fire-inventory-7.svg"
              class="ma-5"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal7 = true"
              >
                Ящик для песка
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal7"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Ящик для песка
                  </p>
                  <p class="subtitle-1 font-weight-light">Предохраняет материал от влаги, устанавливается в местах разлива ЛВЖ или ГЖ; имеет объем 0,5 м³ и комплектуется совковой лопатой</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal7 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <Hint></Hint>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    reveal1: false,
    reveal2: false,
    reveal3: false,
    reveal4: false,
    reveal5: false,
    reveal6: false,
    reveal7: false
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
